import Style from "../../verifications.module.scss";

const Address = ({ state }) => {
  return (
    <div className={`${Style.multicolumn_table}`}>
      <h6 className="pro-ttl h6 pro-mb-2">
        {state?.pendingData?.gli_number ?? "GLI number not found"}
      </h6>
      <div className="">
        <table className="pro-w-100">
          <tbody>
            <tr>
              <td>LOT No</td>
              <td>{state?.pendingData?.lot_number ?? "--"}</td>
            </tr>
            <tr>
              <td>GLI No</td>
              <td>{state?.pendingData?.gli_number ?? "--"}</td>
            </tr>
            <tr>
              <td>Source</td>
              <td>{state?.pendingData?.source?.source ?? "--"}</td>
            </tr>
            <tr>
              <td>Supplier</td>
              <td>{state?.pendingData?.supplier?.name ?? "--"}</td>
            </tr>
            <tr>
              <td>Collection Agent</td>
              <td>{state?.pendingData?.delivery_details?.person ?? "--"}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>
                {state?.pendingData?.delivery_details?.contact_number ?? "--"}
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{state?.pendingData?.delivery_details?.address ?? "--"}</td>
            </tr>
            <tr>
              <td>Shop Name</td>
              <td>{state?.pendingData?.shop?.name ?? "--"}</td>
            </tr>
            <tr>
              <td>Bill to</td>
              <td>
                {state?.pendingData?.billing_address?.shop_name ?? "--"}
                <br />
                {state?.pendingData?.billing_address?.address_line1 ?? "--"}
                <br />
                {state?.pendingData?.billing_address?.address_line2 ?? "--"}
              </td>
            </tr>
            <tr>
              {state?.pendingData?.priority !== null ? (
                <>
                  <td> Priority</td>
                  <td>{state?.pendingData?.priority ?? "--"}</td>
                </>
              ) : null}
              <td></td>
            </tr>
            <tr>
              <td>Job Work</td>
              <td>{state?.pendingData?.is_job_work ?? "--"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Address;
