import { Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import Style from "./remark_card.module.scss";

const RemarkCard = ({ formik, formData, itmIndex,disabled }) => {
  let typeObj = formData?.type?.filter(
    (item) => item?.name === formik?.values?.group[itmIndex].type
  );

  const handleRemoveField = (index) => {
    const data = formik?.values?.group?.filter(
      (_, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("group", data);
  };

  return (
    <div className={Style.container} key={itmIndex}>
      {formik?.values?.group?.length !== 1 && (
        <span
          className={`${Style.corner_button} btn-close`}
          onClick={() => handleRemoveField(itmIndex)}
        ></span>
      )}
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor={`type${itmIndex}`}
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Type
        </label>
        <Select
          id={`type${itmIndex}`}
          name={`type${itmIndex}`}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.touched?.group?.[itmIndex]?.itmIndex &&
            formik.errors?.group?.[itmIndex]?.itmIndex &&
            "error"
          }`}
          classNamePrefix="pro-input"
          options={formData?.type ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          onBlur={formik?.handleBlur(`group.${itmIndex}.type`)}
          isMulti={false}
          onChange={(value) =>
            formik?.setFieldValue(`group.${itmIndex}.type`, value?.name || "")
          }
          menuPlacement="auto"
          value={typeObj}
          isDisabled={disabled}
          
        />
        {formik.touched?.group?.[itmIndex]?.type &&
          formik.errors?.group?.[itmIndex]?.type && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.type}
            </span>
          )}
      </div>
      {
        formik.values?.group?.[itmIndex]?.type === "OTHERS" && (

          <Input
        label={"Others"}
        type="text"
        id={`others${itmIndex}`}
        name={`others${itmIndex}`}
        className={`pro-input lg ${
          formik.touched?.group?.[itmIndex]?.others &&
          formik.errors?.group?.[itmIndex]?.others &&
          "error"
        }`}
        disabled={disabled}
        onChange={(e) =>
          formik?.setFieldValue(`group.${itmIndex}.others`, e?.target?.value)
          // handleOthersChange(e, itmIndex)
        }
        error={
          formik.touched?.group?.[itmIndex]?.others &&
          formik.errors?.group?.[itmIndex]?.others && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.others}
            </span>
          )
        }
        errorMessage={
          formik.touched?.group?.[itmIndex]?.others &&
          formik.errors?.group?.[itmIndex]?.others && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.others}
            </span>
          )
        }
        onBlur={formik?.handleBlur(`group.${itmIndex}.others`)}
        value={formik.values?.group?.[itmIndex]?.others}
      />
        )
      }
      <Input
        label={"Count"}
        type="text"
        id={`count${itmIndex}`}
        name={`count${itmIndex}`}
        className={`pro-input lg ${
          formik.touched?.group?.[itmIndex]?.count &&
          formik.errors?.group?.[itmIndex]?.count &&
          "error"
        }`}
        disabled={disabled}
        // onChange={(e) =>
        //   formik?.setFieldValue(`group.${itmIndex}.count`, e?.target?.value)
        // }
        onChange={(e) => {
          const value = e.target.value;
          if (/^\d*$/.test(value)) {
            formik.setFieldValue(`group.${itmIndex}.count`, value);
          }
        }}
        
        error={
          formik.touched?.group?.[itmIndex]?.count &&
          formik.errors?.group?.[itmIndex]?.count && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.count}
            </span>
          )
        }
        errorMessage={
          formik.touched?.group?.[itmIndex]?.count &&
          formik.errors?.group?.[itmIndex]?.count && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.count}
            </span>
          )
        }
        onBlur={formik?.handleBlur(`group.${itmIndex}.count`)}
        value={formik.values?.group?.[itmIndex]?.count}
      />
      <Input
        label={"Percentage"}
        type="text"
        id={`percentage${itmIndex}`}
        name={`percentage${itmIndex}`}
        className={`pro-input lg ${
          formik.touched?.group?.[itmIndex]?.percentage &&
          formik.errors?.group?.[itmIndex]?.percentage &&
          "error"
        }`}
        disabled={disabled}
        onChange={(e) =>
          formik?.setFieldValue(
            `group.${itmIndex}.percentage`,
            e?.target?.value
          )
        }
        error={
          formik.touched?.group?.[itmIndex]?.percentage &&
          formik.errors?.group?.[itmIndex]?.percentage && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.percentage}
            </span>
          )
        }
        errorMessage={
          formik.touched?.group?.[itmIndex]?.percentage &&
          formik.errors?.group?.[itmIndex]?.percentage && (
            <span className="error-text">
              {formik.errors?.group?.[itmIndex]?.percentage}
            </span>
          )
        }
        onBlur={formik?.handleBlur(`group.${itmIndex}.percentage`)}
        value={formik.values?.group?.[itmIndex]?.percentage}
      />
    </div>
  );
};

export default RemarkCard;
