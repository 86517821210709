import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useMenus = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const handleSetSlug = (slug) => {
    localStorage?.setItem("slug", slug);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const drawerMenu = [
    {
      title: "Employee Dashboard",
      label: "Employee Dashboard",
      link: "/master/dashboard",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">bar_chart_4_bars</span>,
      active:
        checkIfActiveRoute("/dashboard", true) ||
        checkIfActiveRoute("/master/dashboard", true),
    },
    {
      title: "Work Dashboard",
      label: "Work Dashboard",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">bar_chart_4_bars</span>,
      active: checkIfActiveRoute("/overview"),
      subMenu: [
        {
          title: "Orders",
          link: "/overview",
          active: checkIfActiveRoute("/overview", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/overview/recheck",
          active: checkIfActiveRoute("/overview/recheck", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Stock Receipt",
      label: "Stock Receipt",
      link: "/orders",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">inventory</span>,
      active:
        checkIfActiveRoute("/orders", true) ||
        checkIfActiveRoute("/orders/recheck", true),
      subMenu: [
        {
          title: "Orders",
          link: "/orders",
          active: checkIfActiveRoute("/orders", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/orders/recheck?activeTab=certificate",
          active: checkIfActiveRoute("/orders/recheck", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Reassign",
          link: "/orders/reassign?activeTab=orders",
          active: checkIfActiveRoute("/orders/reassign", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Verification",
      label: "Verification",
      link: "/verification",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">new_releases</span>,
      active: checkIfActiveRoute("/verification"),
    },
    {
      title: "QC",
      label: "QC",
      link: "/qc",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">inventory</span>,
      active: checkIfActiveRoute("/orders/qc"),
      // handleClick: () => handleSetSlug("qc"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/qc",
          active: checkIfActiveRoute("/orders/qc", true),
          handleClick: () => handleSetSlug("qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/orders/qc-recheck",
          active: checkIfActiveRoute("/orders/qc-recheck"),
          handleClick: () => handleSetSlug("qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Metal QC",
      label: "Metal QC",
      link: "/orders",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">done_all</span>,
      active: checkIfActiveRoute("/orders/metal-qc"),
      // handleClick: () => handleSetSlug("metal_qc"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/metal-qc",
          active: checkIfActiveRoute("/orders/metal-qc", true),
          handleClick: () => handleSetSlug("metal_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/orders/metal-qc-recheck",
          active: checkIfActiveRoute("/orders/metal-qc-recheck"),
          handleClick: () => handleSetSlug("metal_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Old Gold QC",
      label: "Old Gold QC",
      link: "/qc",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">check_circle</span>,
      active: checkIfActiveRoute(
        "/orders/old-gold-qc" || "/orders/old-gold-qc-recheck"
      ),
      // handleClick: () => handleSetSlug("og_qc"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/old-gold-qc",
          active: checkIfActiveRoute("/orders/old-gold-qc", true),
          handleClick: () => handleSetSlug("og_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/orders/old-gold-qc-recheck",
          active: checkIfActiveRoute("/orders/old-gold-qc-recheck"),
          handleClick: () => handleSetSlug("og_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Final QC",
      label: "Final QC",
      link: "/qc",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">fact_check</span>,
      active: checkIfActiveRoute("/orders/final-qc"),
      // handleClick: () => handleSetSlug("final_qc"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/final-qc",
          active: checkIfActiveRoute("/orders/final-qc", true),
          handleClick: () => handleSetSlug("final_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/orders/final-qc-recheck",
          active: checkIfActiveRoute("/orders/final-qc-recheck"),
          handleClick: () => handleSetSlug("final_qc"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "CVD/RFT",
      label: "CVD/RFT",
      link: "/cvd",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">verified_user</span>,
      active: checkIfActiveRoute("/cvd"),
      // handleClick: () => handleSetSlug("cvd_rft"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/cvd",
          active: checkIfActiveRoute("/orders/cvd", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("cvd_rft"),
        },
        {
          title: "Recheck",
          link: "/orders/cvd-recheck",
          active: checkIfActiveRoute("/orders/cvd-recheck", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("cvd_rft"),
        },
      ],
    },
    {
      title: "Engraving",
      label: "Engraving",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">stylus_note</span>,
      active: checkIfActiveRoute("/engraving"),
      // handleClick: () => handleSetSlug("engraving"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/engraving",
          active: checkIfActiveRoute("/orders/engraving", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("engraving"),
        },
        {
          title: "Recheck",
          link: "/orders/engraving-recheck",
          active: checkIfActiveRoute("/orders/engraving-recheck", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("engraving"),
        },
      ],
    },
    // {
    //   title: "Photo Upload",
    //   label: "Photo Upload",
    //   iconAsset: "DrawerIcon1",
    //   icon: (
    //     <span className="material-symbols-outlined">photo_camera_back</span>
    //   ),
    //   active: checkIfActiveRoute("/photo-upload"),
    //   // handleClick: () => handleSetSlug("photo_section"),
    //   subMenu: [
    //     {
    //       title: "Orders",
    //       link: "/orders/photo-upload",
    //       active: checkIfActiveRoute("/orders/photo-upload", true),
    //       icon: <span className={`sub-icon`}>SR</span>,
    //       handleClick: () => handleSetSlug("photo_section"),
    //     },
    //     {
    //       title: "Recheck",
    //       link: "/orders/photo-upload-recheck",
    //       active: checkIfActiveRoute("/orders/photo-upload-recheck", true),
    //       icon: <span className={`sub-icon`}>SR</span>,
    //       handleClick: () => handleSetSlug("photo_section"),
    //     },
    //   ],
    // },
    {
      title: "Photo Upload",
      label: "Photo Upload",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined">photo_camera_back</span>
      ),
      active: checkIfActiveRoute("/photo-section"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/photo/photo-section",
          active: checkIfActiveRoute("/orders/photo-section", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("photo_section"),
        },
        {
          title: "Recheck",
          link: "/orders/photo/photo-section-recheck",
          active: checkIfActiveRoute(
            "/orders/photo/photo-section-recheck",
            true
          ),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("photo_section"),
        },
        {
          title: "Reissue",
          link: "/orders/photo/photo-section-reissue",
          active: checkIfActiveRoute(
            "/orders/photo/photo-section-reissue",
            true
          ),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("photo_section"),
        },
      ],
    },
    {
      title: "Printing",
      label: "Printing",
      link: "/printing",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">print</span>,
      active: checkIfActiveRoute("/printing"),
      // handleClick: () => handleSetSlug("printing"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/printing/printing-work",
          active: checkIfActiveRoute("/orders/printing/printing-work", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("printing"),
        },
        {
          title: "Recheck",
          link: "/orders/printing-recheck/printing-work-recheck",
          active: checkIfActiveRoute("/orders/printing-recheck"),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("printing"),
        },
        {
          title: "Reissue",
          link: "/orders/printing-reissue/printing-work-reissue",
          active: checkIfActiveRoute("/orders/printing-reissue"),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("printing"),
        },
      ],
    },
    {
      title: "Packing/Final-QC",
      label: "Packing/Final-QC",
      link: "/packing",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">archive</span>,
      active: checkIfActiveRoute("/packing"),
      // handleClick: () => handleSetSlug("packing"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/packing",
          active: checkIfActiveRoute("/orders/packing", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("packing"),
        },
        {
          title: "Recheck",
          link: "/orders/packing-recheck",
          active: checkIfActiveRoute("/orders/packing-recheck"),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("packing"),
        },
      ],
    },
    {
      title: "Billing",
      label: "Billing",
      link: "/billing",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">receipt_long</span>,
      active: checkIfActiveRoute("/billing"),
      subMenu: [
        {
          title: "Orders",
          link: "/billing",
          active: checkIfActiveRoute("/billing", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Recheck",
          link: "/billing/billing-recheck",
          active: checkIfActiveRoute("/billing/billing-recheck", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Reissue",
          link: "/billing/reissue",
          active: checkIfActiveRoute("/billing/reissue", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Estimate",
          link: "/billing/bill-estimate?activeTab=pending",
          active: checkIfActiveRoute("/billing/bill-estimate", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Invoice History",
          link: "/billing/invoice-history",
          active: checkIfActiveRoute("/billing/invoice-history", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
    {
      title: "Report",
      label: "Report",
      link: "",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">summarize</span>,
      active: checkIfActiveRoute("/report"),
      subMenu: [
        {
          title: "DWSR Report",
          link: "/report/dwsr?activeTab=summary",
          active: checkIfActiveRoute("/report/dwsr", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => {},
        },
        {
          title: "Percentage Report",
          link: "/report/percentage",
          active: checkIfActiveRoute("/report/percentage", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => {},
        },
        {
          title: "External Reports",
          link: "/report",
          active: checkIfActiveRoute("/report", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => {},
        },
        {
          title: "Other Reports",
          link: "/report/other?activeTab=Branch",
          active: checkIfActiveRoute("/report/other"),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => {},
        },
        {
          title: "Gli Report",
          link: "/report/gli",
          active: checkIfActiveRoute("/report/gli"),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => {},
        },
      ],
    },
    {
      title: "View Report",
      label: "View Report",
      link: "/view-report",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">summarize</span>,
      active: checkIfActiveRoute("/report"),
    },
    {
      title: "Delivery",
      label: "Delivery",
      link: "/delivery",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined">local_shipping</span>,
      active: checkIfActiveRoute("/delivery"),
      // handleClick: () => handleSetSlug("delivery"),
      subMenu: [
        {
          title: "Orders",
          link: "/orders/delivery/delivery-work",
          active: checkIfActiveRoute("/orders/delivery/delivery-work", true),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("delivery"),
        },
        {
          title: "Recheck",
          link: "/orders/delivery-recheck/delivery-work-recheck",
          active: checkIfActiveRoute(
            "/orders/delivery-recheck/delivery-work-recheck",
            true
          ),
          icon: <span className={`sub-icon`}>SR</span>,
          handleClick: () => handleSetSlug("delivery"),
        },
      ],
    },

    // {
    //   title: "Payment History",
    //   label: "Payment History",
    //   link: "/payment-history",
    //   iconAsset: "DrawerIcon1",
    //   icon: <span className="material-symbols-outlined">currency_rupee</span>,
    //   active: checkIfActiveRoute("/payment-history"),
    // },
    {
      title: "Masters",
      label: "Masters",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined">admin_panel_settings</span>
      ),
      active: checkIfActiveRoute("/master"),
      subMenu: [
        {
          title: "Branches",
          link: "/master/branches",
          active: checkIfActiveRoute("/master/branches"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Source",
          link: "/master/source",
          active: checkIfActiveRoute("/master/source"),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Grading Type",
          link: "/master/item-grading",
          active: checkIfActiveRoute("/master/item-grading", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Item",
          link: "/master/item",
          active: checkIfActiveRoute("/master/item", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Packing Type",
          link: "/master/packing",
          active: checkIfActiveRoute("/master/packing", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Process",
          link: "/master/process",
          active: checkIfActiveRoute("/master/process", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Services",
          link: "/master/service",
          active: checkIfActiveRoute("/master/service", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Item Types",
          link: "/master/item-type",
          active: checkIfActiveRoute("/master/item-type", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Departments",
          link: "/master/department",
          active: checkIfActiveRoute("/master/department", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Role",
          link: "/master/role",
          active: checkIfActiveRoute("/master/role", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Employee",
          link: "/master/employee",
          active: checkIfActiveRoute("/master/employee", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Supplier/Shop",
          link: "/master/shop",
          active: checkIfActiveRoute("/master/shop", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Certificate Prefix",
          link: "/master/certificate-prefix",
          active: checkIfActiveRoute("/master/certificate-prefix", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Invoice Number Format",
          link: "/master/invoice-number-format",
          active: checkIfActiveRoute("/master/invoice-number-format", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Engraving Types",
          link: "/master/engraving",
          active: checkIfActiveRoute("/master/engraving", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Report/Certificate",
          link: "/master/report",
          active: checkIfActiveRoute("/master/report", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
        {
          title: "Billing",
          link: "/master/billing",
          active: checkIfActiveRoute("/master/billing", true),
          icon: <span className={`sub-icon`}>SR</span>,
        },
      ],
    },
  ];
  const filteredMenus = useMemo(() => {
    const allowedMenus =
      globalState.dashboard_permissions?.menu_permissions || [];

    if (!Array.isArray(drawerMenu)) {
      return [];
    }

    const filteredDrawerMenu = drawerMenu?.map((menu) => {
      const matchingBackendMenu = allowedMenus.find(
        (backendMenu) => backendMenu?.menu_name === menu.title
      );

      if (matchingBackendMenu) {
        const subMenuPermissions =
          matchingBackendMenu.sub_menu_permissions?.map(
            (subMenu) => subMenu?.sub_menu_name
          ) || [];

        if (menu.subMenu && Array.isArray(menu?.subMenu)) {
          const filteredSubMenu = menu?.subMenu?.filter((subMenu) =>
            subMenuPermissions.includes(subMenu?.title)
          );

          return {
            ...menu,
            subMenu: filteredSubMenu,
          };
        } else {
          return {
            ...menu,
          };
        }
      }

      return null;
    });

    return filteredDrawerMenu.filter((menu) => menu !== null);
  }, [globalState?.dashboard_permissions, drawerMenu]);

  return { drawerMenu: filteredMenus };
};

export default useMenus;
