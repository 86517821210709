import React from "react";
import useVersionOne from "./useVersionOne";
import {
  Input,
  Image,
  Button,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "../compareTab.module.scss";
import ShowFormTable from "../../ShowFormTable";

const VersionOne = () => {
  const {
    excelFormData,
    showCompare,
    itemID,
    excelData,
    certificateNumber,
    versionOneViewMoreModal,
    handleViewMoreClick,
    handleModalClose,
  } = useVersionOne();
  // const typeKey = Object.keys(excelFormData ?? [])?.[1];

  const typeKey = Object.keys(excelFormData ?? {}).find(key => key === "marked") || 
  Object.keys(excelFormData ?? {}).find(key => key === "rejected");


  const status = [
    {
      id: 2,
      name: "Passed",
    },
    {
      id: 4,
      name: "Rejected",
    },
    {
      id: 5,
      name: "Marked",
    },
  ];

  return (
    <div className="row">
      <div className="col-6">
        <h6 className="pro-ttl h6">{itemID}</h6>
        <p className="pro-ttl">{certificateNumber}</p>
        <small>{"QC"}</small>

        {excelFormData?.data?.map((value, index) => {
          return (
            <div key={index}>
              {value?.slug === "image" ? (
                <>
                  <label>
                    {value?.slug === "image" ? "Image" : value?.name}
                  </label>
                  <div className={`col-2 pro-my-2`}>
                    <div className={``}>
                      <Image
                        width={100}
                        height={100}
                        src={value?.value}
                        alt={`image - 01`}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <Input
                  label={value?.slug === "image" ? "Image" : value?.name}
                  type="text"
                  id={`${value?.slug}`}
                  name={`${value?.slug}`}
                  value={
                    value?.slug === "status"
                      ? status?.find((v) => v.id === value?.value)?.name
                      : value?.value
                  }
                  disabled={true}
                />
              )}
            </div>
          );
        })}



          {["marked", "rejected"].includes(typeKey) &&
                  excelFormData?.[typeKey]?.map((item, index) => (
                    <div key={index} className={`${Style.container}`}>
                      <Input
                        label="Type"
                        type="text"
                        id={`type-${index}`}
                        value={item?.type}
                        disabled={true}
                      />
                      <Input
                        label="Percentage"
                        type="text"
                        id={`percentage-${index}`}
                        value={item?.percentage}
                        disabled={true}
                      />
                      <Input
                        label="Count"
                        type="text"
                        id={`count-${index}`}
                        value={item?.count}
                        disabled={true}
                      />
                    </div>
                  ))}
        {/* Mapping the 'center_stone' object */}
        {excelFormData?.center_stone && <small>{"Center stone"}</small>}
        {excelFormData?.center_stone?.map((value, index) => (
          <div key={index}>
            <Input
              label={value?.name}
              type="text"
              id={`${value?.slug}`}
              name={`${value?.slug}`}
              value={value?.value}
              disabled={true}
            />
          </div>
        ))}
        {/* Mapping the 'side_stone' object */}
        {excelFormData?.side_stone && <small>{"Side stone"}</small>}
        {excelFormData?.side_stone?.map((value, index) => (
          <div key={index}>
            <Input
              label={value?.name}
              type="text"
              id={`${value?.slug}`}
              name={`${value?.slug}`}
              value={value?.value}
              disabled={true}
            />
          </div>
        ))}
      </div>
      <div className="col-6">
        <div className="pro-d-flex pro-justify-end">
          <Button onClick={handleViewMoreClick} className={`pro-btn-primary`}>
            View More
          </Button>
        </div>
        {showCompare && (
          <>
            <h6>{"Excel Data"}</h6>
            <small>QC</small>
            {excelData?.data?.map((value, index) => {
              return (
                <div key={index}>
                  <Input
                    label={value?.name}
                    type="text"
                    id={`${value?.slug}`}
                    name={`${value?.slug}`}
                    value={value?.value}
                    disabled={true}
                  />
                </div>
              );
            })}

            {/* Mapping the 'center_stone' object */}
            {excelData?.center_stone && <small>{"Center stone"}</small>}
            {excelData?.center_stone?.map((value, index) => (
              <div key={index}>
                <Input
                  label={value?.name}
                  type="text"
                  id={`${value?.slug}`}
                  name={`${value?.slug}`}
                  value={value?.value}
                  disabled={true}
                />
              </div>
            ))}
            {/* Mapping the 'side_stone' object */}
            {excelData?.side_stone && <small>{"Side stone"}</small>}
            {excelData?.side_stone?.map((value, index) => (
              <div key={index}>
                <Input
                  label={value?.name}
                  type="text"
                  id={`${value?.slug}`}
                  name={`${value?.slug}`}
                  value={value?.value}
                  disabled={true}
                />
              </div>
            ))}
          </>
        )}
      </div>
      <ModalLayout
        show={versionOneViewMoreModal}
        propStyle={{ root: Style.modal_root }}
        handleClose={handleModalClose}
        title={" "}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <div className="pro-p-5 pro-px-5">
          <ShowFormTable hasButton={false} staticPagination={false} />
        </div>
      </ModalLayout>
    </div>
  );
};

export default VersionOne;
